<template>
  <div>
    <DetailTemplate
      :customClass="'customer-detail detail-page'"
      v-if="getPermission('customer:view')"
    >
      <template v-slot:header-title>
        <div class="d-flex align-center">
          <!--  <span class="color-custom-blue font-weight-700 font-size-20 mr-2" v-if="warrantyArr && warrantyArr.customer && warrantyArr.customer.display_name"
          >{{warrantyArr.customer.display_name}}</span
        > -->
          <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3 mr-2"
            text-color=""
            style="font-size: 21px !important; font-weight: 700"
          >
            {{ warrantyArr.unique_id }}
          </v-chip>
          <v-chip
            :color="getColor(warrantyArr.status)"
            label
            v-if="warrantyArr.status"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(warrantyArr.status) }}
            </span>
          </v-chip>
          {{ warrantyArr.extended }}
          <template
            v-if="
              warrantyArr.extended_id &&
              warrantyArr.is_extended &&
              (totalExtented(warrantyArr) > 0 ||
                totalExtented(warrantyArr) == 0)
            "
          >
            <v-chip
              label
              class="font-weight-600 custom-grey-border text-uppercase ml-2 mb-1"
              text-color="white"
              color="green"
            >
              Active
            </v-chip>
          </template>
          <v-chip
            v-if="
              warrantyArr &&
              warrantyArr.equipment &&
              warrantyArr.equipment.main_type == 'main_company'
            "
            label
            color="#0d47a1"
            outlined
            class="p-3 ml-2"
          >
            Own Equipment
          </v-chip>
        </div>
      </template>
      <template v-slot:header-action>
        <div class="d-flex align-center justify-end">
          <!--  <template v-if="warrantyArr.status == 1">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="pageLoading"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-pencil</v-icon> Edit
                  </v-btn>
                </template>
                <span>Click here to edit Contract</span>
              </v-tooltip>
            </template> -->
          <template
            v-if="
              !warrantyArr.is_extended &&
              warrantyArr.status != 4 &&
              warrantyArr.status != 1
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="getWarranty('extend')"
                >
                  <v-icon left>mdi-update</v-icon> Extend
                </v-btn>
              </template>
              <span>Extend Warranty</span>
            </v-tooltip>
          </template>
          <template
            v-if="
              warrantyArr.status != 4 &&
              warrantyArr.status != 1 &&
              warrantyArr.status != 5
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="terminateWarranty"
                >
                  <v-icon left>mdi-update</v-icon> Terminate
                </v-btn>
              </template>
              <span>Terminate Warranty</span>
            </v-tooltip>
          </template>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="goBack()"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </div>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="
            max-height: calc(100vh - 185px);
            height: calc(100vh - 185px);
            position: relative;
          "
        >
          <v-container fluid>
            <v-row>
              <v-col md="6">
                <div class="new-white-box-overview-main nav">
                  <div class="col-md-5 customer-image text-center">
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="image"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <v-img
                        :lazy-src="$defaultImage"
                        :src="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.image
                        "
                        aspect-ratio="1"
                        max-height="200"
                        max-width="200"
                        class="margin-auto custom-grey-border custom-border-radius-50"
                        transition="fade-transition"
                      >
                      </v-img>
                    </template>
                  </div>

                  <div class="col-md-7 gray-background pt-0">
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="text@5"
                    >
                    </v-skeleton-loader>

                    <table v-else class="width-100">
                      <span
                        class="color-custom-blue font-weight-700 font-size-19 mb-5"
                      >
                        Equipment Detail
                        <div
                          v-if="warrantyArr && warrantyArr.equipment"
                          class="d-flex ml-2 mt-1"
                        >
                          <v-chip
                            label
                            :color="
                              warrantyArr.equipment.main_type == 'main_customer'
                                ? '#4CAF50'
                                : '#0D47A1'
                            "
                            class="text-white p-3 mr-3 mb-1"
                            small
                          >
                            <template
                              v-if="
                                warrantyArr.equipment.main_type ==
                                'main_customer'
                              "
                            >
                              Customer
                            </template>
                            <template v-else> Company </template>
                          </v-chip>
                          <v-chip
                            label
                            :color="
                              warrantyArr.equipment.type == 'personal'
                                ? 'cyan'
                                : 'red'
                            "
                            outlined
                            small
                            class="text-white p-3 mr-3"
                          >
                            <template
                              v-if="warrantyArr.equipment.type == 'personal'"
                            >
                              Customer/Own
                            </template>
                            <template v-else> {{ appCompanyName }} </template>
                          </v-chip>
                        </div>
                      </span>
                      <template>
                        <tr>
                          <td
                            class="pb-2 font-size-18 text-capitalize"
                            style="width: 160px"
                          >
                            Equipment Name
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <span
                              class="fw-500 no-wrap"
                              v-if="
                                warrantyArr &&
                                warrantyArr.equipment &&
                                warrantyArr.equipment.name
                              "
                            >
                              {{ warrantyArr.equipment.name }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Serial no
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.serial_no
                            "
                          >
                            {{ warrantyArr.equipment.serial_no }}
                          </td>
                          <em v-else class="text-muted ml-2"> no Serial no</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Model
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.model
                            "
                          >
                            <span class="fw-500 no-wrap">
                              {{ warrantyArr.equipment.model }}
                            </span>
                          </td>
                          <em v-else class="text-muted ml-2"> no model</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Brand
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.brand &&
                              warrantyArr.equipment.brand
                            "
                          >
                            {{ warrantyArr.equipment.brand }}
                          </td>
                          <em v-else class="text-muted ml-2"> no Brand</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Location
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.location &&
                              warrantyArr.equipment.location.text
                            "
                          >
                            {{ warrantyArr.equipment.location.text }}
                          </td>
                          <em v-else class="text-muted ml-2"> no Location</em>
                        </tr>
                      </template>
                    </table>
                  </div>
                </div>
              </v-col>
              <v-col md="6" class="custom-border-left gray-background mb-5">
                <span
                  class="color-custom-blue font-weight-700 font-size-19 mb-4"
                >
                  Warranty Detail
                </span>
                <table width="100%">
                  <tr></tr>
                  <tr></tr>
                  <tr>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Serial No.
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.unique_id"
                      >
                        {{ warrantyArr.unique_id }}
                      </label>
                      <em v-else class="text--secondary"> Serial No</em>
                    </td>
                  </tr>

                  <tr>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Start Date
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.start_date"
                      >
                        {{ formatDate(warrantyArr.start_date) }}
                      </label>
                      <em v-else class="text--secondary"> no start date </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      End Date
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.end_date"
                      >
                        {{ formatDate(warrantyArr.end_date) }}
                      </label>
                      <em v-else class="text--secondary"> no end date </em>
                    </td>
                  </tr>
                  <tr v-if="warrantyArr && warrantyArr.end_date">
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Expiring on
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.end_date"
                      >
                        <b>
                          {{ formatDate(warrantyArr.end_date) }}
                          midnight.</b
                        >
                      </label>
                    </td>
                  </tr>
                  <tr v-if="warrantyArr && warrantyArr.end_date">
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Remaining
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.end_date"
                      >
                        <b>
                          {{ remaningDate(warrantyArr) }}
                          <span style="color: red; font-weight: 700"
                            >({{ totalDays(warrantyArr) }} Days)</span
                          ></b
                        >
                      </label>
                    </td>
                  </tr>
                  <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Duration
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"> 4(Days) </label>
                  </td>
                </tr> -->
                  <tr>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                      style="width: 140px"
                    >
                      Description
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500 d-flex"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.remark"
                      >
                        {{ warrantyArr.remark }}
                      </label>
                      <em v-else class="text--secondary"> no Description </em>
                    </td>
                  </tr>
                  <tr v-if="warrantyArr && warrantyArr.terminate_date">
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                    >
                      Terminate Date
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500"
                    >
                      <label
                        class="m-0"
                        v-if="warrantyArr && warrantyArr.terminate_date"
                      >
                        {{ formatDate(warrantyArr.terminate_date) }}
                      </label>
                      <em v-else class="text--secondary"> no end date </em>
                    </td>
                  </tr>
                  <tr v-if="warrantyArr && warrantyArr.terminate_reason">
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-600"
                      style="width: 41px"
                    >
                      Terminate Reason
                    </td>
                    <td
                      valign="middle"
                      class="py-1 font-size-18 font-weight-500 d-flex"
                    >
                      <label
                        class="m-0"
                        style="color: red !important"
                        v-if="warrantyArr && warrantyArr.terminate_reason"
                      >
                        {{ warrantyArr.terminate_reason }}
                      </label>
                      <em v-else class="text--secondary">
                        no Terminate Reason
                      </em>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="12">
                <v-tabs
                  active-class="custom-tab-active"
                  v-model="contractTab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent tab-sticky"
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#overview"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/attachment.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Overview
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#attachment"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/attachment.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Attachment
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="contractTab">
                  <v-tab-item value="attachment">
                    <AdditionalDocuments
                      :type-id="warrantyArr.id"
                      class="mx-4"
                      type-text="Warranty"
                      :type="115"
                      :extensive="false"
                    ></AdditionalDocuments>
                  </v-tab-item>
                  <v-tab-item value="overview">
                    <v-layout class="my-4">
                      <v-flex md5 class="mr-2 custom-border-right">
                        <table class="width-100">
                          <span
                            class="color-custom-blue font-weight-700 font-size-19 mb-4"
                          >
                            <template
                              v-if="
                                warrantyArr &&
                                warrantyArr.equipment &&
                                warrantyArr.equipment.main_type ==
                                  'main_customer'
                              "
                            >
                              Customer Detail</template
                            >
                            <template v-else> Site Location </template>
                          </span>
                          <template
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.main_type == 'main_customer'
                            "
                          >
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                style="width: 300px"
                              >
                                Type
                              </td>
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer
                                "
                              >
                                <span class="fw-500 no-wrap">
                                  <v-chip
                                    color="blue white--text"
                                    label
                                    v-if="
                                      warrantyArr.equipment.customer
                                        .company_type == 'company'
                                    "
                                  >
                                    <span class="font-size-16 font-weight-500">
                                      Company
                                    </span>
                                  </v-chip>
                                  <v-chip
                                    color="red white--text"
                                    label
                                    v-if="
                                      warrantyArr.equipment.customer
                                        .company_type == 'personal'
                                    "
                                  >
                                    <span class="font-size-16 font-weight-500">
                                      Individual
                                    </span>
                                  </v-chip>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                style="width: 300px"
                              >
                                Display Name
                              </td>
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.display_name
                                "
                              >
                                <span class="fw-500 no-wrap">
                                  {{
                                    warrantyArr.equipment.customer.display_name
                                  }}
                                </span>
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Display Name</em
                              >
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                Company Name
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_name
                                "
                              >
                                {{
                                  warrantyArr.equipment.customer.company_name
                                }}
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Company Name</em
                              >
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                Company Email
                              </td>
                              <td
                                class="pb-2 font-size-18 font-weight-600"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_email
                                "
                              >
                                <span class="fw-500 no-wrap">
                                  {{
                                    warrantyArr.equipment.customer.company_email
                                  }}
                                </span>
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Company Email</em
                              >
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                Company Number
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_number
                                "
                              >
                                {{
                                  warrantyArr.equipment.customer.company_number
                                }}
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Company Number</em
                              >
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                Company Fax
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_fax
                                "
                              >
                                {{ warrantyArr.equipment.customer.company_fax }}
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Company Fax</em
                              >
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                UEN
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.uen
                                "
                              >
                                {{ warrantyArr.equipment.customer.uen }}
                              </td>
                              <em v-else class="text-muted ml-2"> no UEN</em>
                            </tr>
                            <tr>
                              <td class="pb-2 font-size-18 text-capitalize">
                                Website
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 font-weight-600 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.website
                                "
                              >
                                {{ warrantyArr.equipment.customer.website }}
                              </td>
                              <em v-else class="text-muted ml-2">
                                no Website</em
                              >
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                style="width: 300px"
                              >
                                <span class="font-weight-700"
                                  ><template
                                    v-if="warrantyArr && warrantyArr.entity"
                                  >
                                    {{ getFormattedCompany() }}
                                  </template></span
                                >
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-flex>
                      <v-flex
                        md3
                        class="mr-2 custom-border-right"
                        v-if="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.main_type == 'main_customer'
                        "
                      >
                        <table width="100%">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              Contact details
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18 font-weight-500">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .display_name
                                "
                              >
                                <v-icon small>mdi-account</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .display_name
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18 font-weight-500">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .primary_phone
                                "
                              >
                                <v-icon small>mdi-phone</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .primary_phone
                              }}
                            </td>
                          </tr>
                          <tr v-else class="py-0 font-size-18 font-weight-500">
                            <em class="text-muted ml-2"> no phone number</em>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18 font-weight-500">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .primary_email
                                "
                              >
                                <v-icon small>mdi-email</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .primary_email
                              }}
                            </td>
                          </tr>
                          <tr v-else class="py-0 font-size-18 font-weight-500">
                            <em class="text-muted ml-2"> no email address</em>
                          </tr>
                        </table>
                      </v-flex>
                      <v-flex
                        md3
                        class="ml-2"
                        v-if="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.main_type == 'main_customer'
                        "
                      >
                        <table width="100%">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              <label>Site Location</label>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property
                            "
                          >
                            <td class="py-0 d-flex">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property
                                    .property_address
                                "
                              >
                                <v-icon small>mdi-map-marker</v-icon></span
                              >
                              <label
                                v-if="
                                  warrantyArr && warrantyArr.equipment.property
                                "
                                ><template
                                  v-if="warrantyArr.equipment.property.street_1"
                                  >{{
                                    warrantyArr.equipment.property.street_1
                                  }},</template
                                >
                                <br
                                  v-if="
                                    warrantyArr.equipment.property.street_2 ||
                                    warrantyArr.equipment.property.unit_no
                                  "
                                />
                                <template
                                  v-if="warrantyArr.equipment.property.street_2"
                                >
                                  {{ warrantyArr.equipment.property.street_2 }},
                                </template>
                                <template
                                  v-if="warrantyArr.equipment.property.unit_no"
                                >
                                  {{ warrantyArr.equipment.property.unit_no }},
                                </template>
                                <br />
                                {{ warrantyArr.equipment.property.country }}
                                <template
                                  v-if="
                                    warrantyArr.equipment.property.zip_code &&
                                    warrantyArr.equipment.property.zip_code !=
                                      '000000'
                                  "
                                >
                                  ,
                                  {{ warrantyArr.equipment.property.zip_code }}
                                </template>
                              </label>
                              <!--  <label
                                v-if="
                                  warrantyArr.equipment.property
                                    .property_address
                                "
                                >{{ warrantyArr.equipment.property.street_1 }},
                                <br
                                  v-if="
                                    warrantyArr.equipment.property.street_2 ||
                                    warrantyArr.equipment.property.unit_no
                                  "
                                />
                                <template
                                  v-if="warrantyArr.equipment.property.street_2"
                                >
                                  {{ warrantyArr.equipment.property.street_2 }},
                                </template>
                                <template
                                  v-if="warrantyArr.equipment.property.unit_no"
                                >
                                  {{ warrantyArr.equipment.property.unit_no }},
                                </template>
                                <br />
                                {{ warrantyArr.equipment.property.country }},
                                {{ warrantyArr.equipment.property.zip_code }},
                              </label> -->
                            </td>
                          </tr>
                          <em v-else class="text-muted ml-2">
                            no Site Location</em
                          >
                        </table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </template>
      <template v-slot:footer> </template>
    </DetailTemplate>
    <ExtentWarranty
      v-if="extentWarrantyDialog"
      :warrantyDetail="warrantyArr"
      :dialogStatus="extentWarrantyDialog"
      v-on:close:dialog="extentWarrantyDialog = false"
      v-on:update:warranty="getWarranty"
    ></ExtentWarranty>
    <TerminatWarranty
      v-if="teminateWarrantyDialog"
      :warrantyDetail="warrantyArr"
      :dialogStatus="teminateWarrantyDialog"
      v-on:close:dialog="teminateWarrantyDialog = false"
      v-on:update:warranty="getWarranty"
    ></TerminatWarranty>
  </div>
</template>
<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET /* , PATCH */ } from "@/core/services/store/request.module";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import ExtentWarranty from "@/view/pages/warranty/Extended-Warranty.vue";
import TerminatWarranty from "@/view/pages/warranty/Terminat-Warranty.vue";
import moment from "moment";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "customer-detail",
  title: "Customer",
  data() {
    return {
      pageLoading: false,
      contractTab: "overview",
      warranty: 0,
      extentWarrantyDialog: false,
      teminateWarrantyDialog: false,
      warrantyArr: {},
    };
  },
  components: {
    DetailTemplate,
    AdditionalDocuments,
    ExtentWarranty,
    TerminatWarranty,
  },
  methods: {
    terminateWarranty() {
      this.teminateWarrantyDialog = true;
    },
    totalExtented(date) {
      var a = moment(date.start_date).format("YYYY-MM-DD");
      var b = moment().format("YYYY-MM-DD");

      if (a && b) {
        var final = moment(b).diff(moment(a), "days"); // =1
        return final;
      }
    },
    /*  remaningDate(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days == 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months == 0) {
        months = "";
      } else {
        months = months + " Months";
      }
      if (years == 0) {
        years = "";
      } else {
        years = years + " Years";
      }

      return years + months + days;
    }, */

    remaningDate(date) {
      // var a = moment(date.end_date);
      // var b = moment(date.start_date);

      // var years = a.diff(b, "year");
      // b.add(years, "years");

      // var months = a.diff(b, "months");
      // b.add(months, "months");

      // var days = a.diff(b, "days");
      // if(days == 0){
      //   days = '';
      // }else{
      //   days = days + " Days"
      // }
      // if(months == 0){
      //   months = '';
      // }else{
      //   months = months + " Months"
      // }
      // if(years == 0){
      //   years = '';
      // }else{
      //   years = years + " Years"
      // }
      // return years + months + days;

      var a = moment(date.end_date);
      //var b = moment(date.start_date);
      //console.log(b);
      var b = moment().format("YYYY-MM-DD");
      b = moment(b);
      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days <= 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months <= 0) {
        months = "";
      } else {
        months = months + " Months ";
      }
      if (years <= 0) {
        years = "";
      } else {
        years = years + " Years ";
      }
      return years + months + days;
    },
    totalDays(date) {
      // var a =  moment(date.end_date);
      // var b =  moment(date.start_date);
      // var final =   a.diff(b, 'days')   // =1
      // return final;
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },

    getFormattedCompany() {
      let tempArr = [];
      if (this.warrantyArr.entity.unit_no) {
        tempArr.push(this.warrantyArr.entity.unit_no);
      }
      if (this.warrantyArr.entity.street_1) {
        tempArr.push(this.warrantyArr.entity.street_1);
      }
      if (this.warrantyArr.entity.street_2) {
        tempArr.push(this.warrantyArr.entity.street_2);
      }

      if (
        this.warrantyArr.entity.zip_code &&
        this.warrantyArr.entity.zip_code != "000000"
      ) {
        tempArr.push(this.warrantyArr.entity.zip_code);
      }
      if (this.warrantyArr.entity.landmark) {
        tempArr.push(this.warrantyArr.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    /*   totalDays(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);
      var final = a.diff(b, "days"); // =1
      return final;
    }, */
    getStatus(status) {
      if (status == 1) {
        return "Draft";
      } else if (status == 2) {
        return "Active";
      } else if (status == 3) {
        return "Extended";
      } else if (status == 4) {
        return "Terminated";
      } else if (status == 5) {
        return "Expired";
      }
    },
    getColor(status) {
      if (status == 1) {
        return "cyan white--text";
      } else if (status == 2) {
        return "green white--text";
      } else if (status == 3) {
        return "orange white--text";
      } else if (status == 4) {
        return "red white--text";
      } else if (status == 5) {
        return "teal white--text";
      }
    },
    getWarranty(type) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "warranty/" + _this.warranty,
        })
        .then(({ data }) => {
          _this.warrantyArr = data;
          if (type && type == "extend") {
            this.extentWarrantyDialog = true;
          }
          /*   _this.warrantyMoreAction = data.more_actions; */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    if (this.warranty > 0) {
      this.getWarranty();
    }
  },
  created() {
    const _this = this;
    _this.warranty = _this.lodash.toSafeInteger(_this.$route.params.id);
  },
};
</script>
