<template>
  <div>
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex class="font-level-3-bold my-auto">
        <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
          <!--begin::Svg Icon-->
          <inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
          <!--end::Svg Icon-->
          <template v-if="typeText">
            <span class="font-weight-700">
              {{ typeText }}
            </span>
          </template>
          <template v-else
            ><span class="font-weight-700">Files</span>
          </template>
        </span>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          v-on:click="add_document_dialog = true"
          color="cyan text-white"
          class="ml-2"
          depressed
          tile
          ><v-icon small left>mdi-playlist-plus</v-icon>Attach File
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="overflow-y" style="max-height: calc(100vh - 345px)">
      <table width="100%" class="detail-table table-head-sticky">
        <thead>
          <tr style="border: 1px solid lightgray">
            <th class="p-2 light-blue-bg" width="200"></th>
            <th class="p-2 light-blue-bg" width="250">Name</th>
            <th v-if="extensive" class="p-2 light-blue-bg" width="200">
              Dates
            </th>
            <th v-if="extensive" class="p-2 light-blue-bg" width="300">Tags</th>
            <th v-if="extensive" class="p-2 light-blue-bg" width="300">
              Description
            </th>
          </tr>
        </thead>

        <tbody v-if="dbFiles.length">
          <tr
            v-for="(row, index) in dbFiles"
            :key="index"
            style="border: 1px solid lightgray"
          >
            <td class="p-2 border-top-light-grey" width="50">
              <template v-if="row.extension && isImage(row.extension)">
                <ImageTemplate
                  :src="row.file.url"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.extension}.svg`)"
                />
              </template>
            </td>
            <td class="p-2 border-top-light-grey" width="250">
              <p
                class="m-0 blue--text font-level-1 cursor-pointer bold-600"
                v-on:click="doAction(row, 'download')"
              >
                {{ row.name }}
              </p>
              <div v-if="is_main" class="d-flex my-1">
                <p class="m-0 font-level-1 cursor-pointer bold-600">Type :</p>
                <v-badge
                  class="mt-2 ml-2"
                  :color="row.type_color ? row.type_color : '#58b5bd'"
                  :content="row.type.toUpperCase()"
                ></v-badge>
              </div>

              <p class="m-0 text-muted font-small w-100">
                {{ formatDate(row.added_at) }}
              </p>
              <p class="m-0 text-muted font-small w-100">
                by {{ row.user_display_name }}
              </p>
            </td>
            <td v-if="extensive" class="p-2 border-top-light-grey">
              <template v-if="row.start_date && row.end_date">
                <div class="d-flex align-items-center">
                  <div
                    :class="`${
                      row.end_date && checkWExpDate(row.end_date)
                        ? 'expired'
                        : ''
                    }`"
                  >
                    <div class="d-flex align-items-center">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            v-on="on"
                            class="font-size-14 mb-1 mr-7"
                          >
                            <v-badge
                              color="cyan"
                              content="S"
                              class="badge-new"
                            ></v-badge>
                          </label>
                        </template>
                        <span> Start Date </span>
                      </v-tooltip>
                      <v-chip label class="mb-2">
                        {{ formatDate(row.start_date) }}
                      </v-chip>
                    </div>

                    <div class="d-flex align-items-center">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            v-on="on"
                            class="font-size-14 mb-1 mr-7"
                          >
                            <v-badge
                              color="red"
                              content="E"
                              class="badge-new"
                            ></v-badge>
                          </label>
                        </template>
                        <span> End Date </span>
                      </v-tooltip>
                      <v-chip label class="mb-2">
                        {{ formatDate(row.end_date) }}
                      </v-chip>
                    </div>
                    <div class="d-flex align-items-center">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            v-on="on"
                            class="font-size-14 mb-1 mr-7"
                          >
                            <v-badge
                              color="orange"
                              content="R"
                              class="badge-new"
                            ></v-badge>
                          </label>
                        </template>
                        <span> Reminder Date </span>
                      </v-tooltip>
                      <v-chip label class="mb-2">
                        {{ formatDate(row.reminder_date) }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <em class="text--secondary">No Dates</em>
              </template>
            </td>

            <td v-if="extensive" class="p-2 border-top-light-grey">
              <template v-if="row.tags && row.tags.length > 0">
                <v-chip
                  v-for="(tagRow, index) in row.tags"
                  :key="`tag-${index}`"
                  class="ma-2 tag"
                  size="x-small"
                  color="#58b5bd"
                  text-color="white"
                >
                  {{ tagRow }}
                </v-chip>
              </template>
              <template v-else>
                <em class="text--secondary">No Tags Data</em>
              </template>
            </td>
            <td
              v-if="extensive"
              class="p-2 border-top-light-grey font-level-1"
              width="50%"
            >
              <div class="d-flex justify-space-between">
                <template
                  v-if="row && row.description && row.description != 'null'"
                >
                  {{ row.description }}
                </template>
                <template v-else>
                  <em class="text--secondary"> No Description</em>
                </template>
                <div md1>{{ getFileSize(row.size) }} KB</div>
              </div>
            </td>
            <td class="p-2 border-top-light-grey font-level-1" width="150">
              <v-btn
                v-on:click="doAction(row, 'download')"
                icon
                depressed
                color="blue darken-4"
                class="mr-2"
                ><v-icon medium>mdi-download</v-icon></v-btn
              >
              <v-btn
                v-on:click="doAction(row, 'delete')"
                icon
                depressed
                color="red lighten-1"
                ><v-icon medium>mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="6">
              <p class="m-0 row-not-found text-center">
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no file at the moment.
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <DocumentwithName
      :document-dialog="add_document_dialog"
      v-on:close="add_document_dialog = false"
      v-on:success="getFiles()"
      :document-type="type"
      :document-id="typeId"
      :extensive="extensive"
      :parent-type="typeParent"
      :parent-type-id="typeParentId"
    >
    </DocumentwithName>
    <Dialog :commonDialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              Deleting File is irreversible, Are you sure about deleting it?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { toNumber, round, find } from "lodash";
import ImageTemplate from "@/view/pages/Image";
//import Dialog from "@/view/components/Dialog";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
import DocumentwithName from "@/view/pages/DocumentwithName";
//import { EventBus } from "@/core/event-bus/event.bus";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
    typeParent: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: null,
    },
    typeParentId: {
      type: Number,
      default: null,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    extensive: {
      type: Boolean,
      default: false,
    },
    is_main: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    reload(param) {
      if (param) {
        this.getFiles();
      }
    },
    typeId(param) {
      if (param) {
        this.getFiles();
      }
    },
  },
  data() {
    return {
      pageLoading: true,
      deleteLoading: false,
      deleteDialog: false,
      attachDialog: false,
      attachLoading: false,
      uuid: null,
      description: null,
      files: [],
      dbFiles: [],
      add_document_dialog: false,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
    };
  },
  methods: {
    init() {
      this.deleteLoading = false;
      this.deleteDialog = false;
      this.attachDialog = false;
      this.attachLoading = false;
      this.uuid = null;
      this.description = null;
      this.files = [];
    },
    updateFiles(param) {
      if (param) {
        const max_size = toNumber(this.$uploadSizeLIMIT);
        const max_file = toNumber(this.$uploadLIMIT);
        for (let i = 0; i < param.length; i++) {
          const size = round(toNumber(param[i].size / 1024), 2);
          if (size <= max_size && this.files.length < max_file) {
            this.files.push(param[i]);
          }
        }
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$refs["upload-file"].value = null;
    },
    maxFileSize() {
      return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
    },
    selectFile() {
      this.$refs["upload-file"].reset();
      this.$refs["upload-file"].$refs["input"].value = null;
      this.$nextTick(() => {
        this.$refs["upload-file"].$refs["input"].click();
      });
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file.url, "_blank");
          break;
        case "delete":
          this.id = row.id;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.id) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.setHeader();
      ApiService.delete(`delete-documents/${this.id}`)

        .then(() => {
          this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    getFiles() {
      ApiService.setHeader();
      ApiService.get(`documents/${this.type}/${this.typeId}`)

        .then(({ data }) => {
          this.dbFiles = data.data;
          console.log(data);
          this.init();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    attachFile() {
      const params = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        params.append(`file[${i}]`, this.files[i]);
      }

      params.append("description", this.description);

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload(`${this.type}/file`, params)
        .then(() => {
          this.getFiles();
          this.$refs["upload-file"].value = null;
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkWExpDate(date) {
      /* let currentData = MomentJS();
			let enddate = MomentJS(date, "DD/MM/YYYY");
			console.log(enddate.diff(currentData, "days"));
			if (enddate.diff(currentData, "days") > 0) {
				return false;
			}
			return true; */

      let currentData = moment();
      let enddate = moment(date, "YYYY-MM-DD");
      if (enddate.diff(currentData, "days") >= 0) {
        return false;
      }
      return true;
    },
  },
  components: {
    Dialog,
    //	TextAreaInput,
    ImageTemplate,
    DocumentwithName,
  },
  mounted() {
    if (this.typeId > 0) {
      this.getFiles();
    }

    // EventBus.$on("reload:asset", () => {
    // 	this.getFiles();
    // });
  },
};
</script>
<style scoped>
.custom-min-height-60vh {
  height: 77vh;
}
.v-window {
  overflow: auto !important;
}
.v-chip.v-size--default.tag {
  height: auto !important;
  padding: 1px 8px !important;
}
</style>
